<template>
  <hub-taskboard />
</template>

<script>
import Board from './../components/boards/Board.vue';

export default {
  components: {
    'hub-taskboard': Board
  },
  mounted() {}
};
</script>
