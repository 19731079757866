<template>
  <Loading v-if="!isReady" />
  <section v-else-if="!!item" class="board">
    <header>
      <router-link to="/boards">
        <hub-icon name="arrow-left"></hub-icon>
      </router-link>
      <h2>
        {{ item.title }}
      </h2>
      <div v-if="selectedTasks.length" class="mass-action-wrapper">
        <hub-button variant="icon" @click="selectedTasks = []">
          <hub-icon name="close" />
        </hub-button>
        <hub-button-dropdown
          :options="[
            {
              label: 'Add assignees',
              icon: 'account-plus',
              key: 'add'
            },
            {
              label: 'Reassign ',
              icon: 'account-switch',
              key: 'assign'
            }
          ]"
          @click="onMassAction"
          >{{ selectedTasks.length }} {{ selectedTasks.length === 1 ? 'task' : 'tasks' }} selected</hub-button-dropdown
        >
      </div>
      <template v-if="canEditBoard">
        <hub-button variant="icon" color="primary" :test-id="'create-button'" @click="createModalVisible = true">
          <hub-icon name="playlist-plus" />
        </hub-button>
      </template>
    </header>
    <div v-if="item.filters.length" style="width: 100%; height: 100%" class="taskboard">
      <component
        :is="wrapperComponent"
        v-for="(sublist, index) in filtersArr"
        :key="index"
        :list="sublist"
        class="sublist"
        :style="{ 'grid-template-rows': `repeat(${sublist.length}, 1fr )` }"
        style="overflow-y: hidden; height: 100%;"
        group="board"
        @change="update"
      >
        <div v-for="id in sublist" :key="id" style="overflow-y: hidden; height: 100%;">
          <hub-task-list
            :id="id"
            :can-edit-board="canEditBoard"
            :selected-tasks="selectedTasks"
            :auto-refresh="true"
            :expandable="true"
            @open="open"
            @remove="removeFilter(index, id)"
            @taskToggled="taskToggled"
            @edited="taskEdited"
          >
            <template #actions="{ item }">
              <hub-button-dropdown
                v-if="canEditBoard"
                :options="[{ label: 'Remove', key: 'remove', icon: 'close' }]"
                @click="$event => action(index, item.id, $event)"
              />
            </template>
          </hub-task-list>
        </div>
      </component>
    </div>
    <!-- </component> -->
    <div v-else style="place-self: center"><i style="font-style: italic">Select an existing filter or create a new one.</i></div>
    <TaskModal :selected="selected" @close="close" @edited="taskEdited" />
    <hub-manage-filters-modal
      v-if="createModalVisible"
      :selected="filtersArr"
      @close="createModalVisible = false"
      @created="filterCreated"
      @filtersListChanged="filtersListChanged"
      @filterUpdated="filterUpdated"
    />
    <EditAssigneesModal
      v-if="editAssigneesPopupOptions.mode"
      :mode="editAssigneesPopupOptions.mode"
      @close="editAssigneesPopupOptions.mode = null"
      @submit="assigneesMassAction"
    />
  </section>
</template>

<script>
import { mapState } from 'vuex';

import List from './ListWithAutoload';
import Button from './../common/Button';
import Icon from './../common/Icon';
import EditTask from './../inventions/tasks/EditTask';
import TaskModal from '@/components/inventions/tasks/TaskModal';
import Menu from './../common/Menu';
import ManageFiltersModal from './filters/ManageFiltersModal';
import { VueDraggableNext } from 'vue-draggable-next';
import EditAssigneesModal from './EditAssigneesModal.vue';
import Loading from '@/components/common/Loading';

export default {
  components: {
    draggable: VueDraggableNext,
    'hub-button': Button,
    'hub-button-dropdown': Menu,
    'hub-icon': Icon,
    'hub-task-list': List,
    'hub-edit-task': EditTask,
    'hub-manage-filters-modal': ManageFiltersModal,
    EditAssigneesModal,
    TaskModal,
    Loading
  },
  data() {
    return {
      isReady: false,
      selected: null,
      createModalVisible: false,
      selectedTasks: [],
      editAssigneesPopupOptions: {
        mode: null
      },
      timeoutRef: null,
      isRefreshing: false,
      filtersArr: []
    };
  },
  computed: {
    ...mapState({
      item: s => s.boards.item,
      email: s => s.identity.email
    }),
    canEditBoard() {
      return this.item.createdBy === this.email || this.item.owners.includes(this.email) || this.item.owners.includes('everyone');
    },
    wrapperComponent() {
      return this.canEditBoard ? 'draggable' : 'div';
    }
  },
  watch: {
    'item.filters'(nw) {
      if (!this.item) {
        this.filtersArr = [];
      }
      const prepaired = this.item.filters.map(filter => {
        if (Array.isArray(filter)) {
          return filter;
        }
        return [filter];
      });

      this.filtersArr = [...prepaired, []];
    }
  },

  async created() {
    this.$trackEvent(`Board opened`);
    await this.$store.dispatch('tasks/getTemplateCollection');

    this.isReady = false;
    await this.$store.dispatch('filters/getCollection');
    await this.initialize();
    this.isReady = true;
    this.executeRefreshTimeout();
    document.addEventListener('visibilitychange', this.subscribeToVisibilityChange);
  },
  unmounted() {
    clearTimeout(this.timeoutRef);
    document.removeEventListener('visibilitychange', this.subscribeToVisibilityChange);
  },
  methods: {
    async subscribeToVisibilityChange() {
      if (document.hidden) {
        if (!this.isRefreshing) {
          clearTimeout(this.timeoutRef);
        }
      } else {
        await this.refresh();
        this.executeRefreshTimeout();
      }
    },
    executeRefreshTimeout() {
      this.timeoutRef = setTimeout(async () => {
        if (!this.isRefreshing) {
          this.isRefreshing = true;
          await this.refresh();
          this.isRefreshing = false;
          if (!document.hidden) {
            this.executeRefreshTimeout();
          }
        }
      }, 60 * 1000);
    },
    async initialize() {
      this.selected = null;
      await this.$store.dispatch('boards/getById', this.$route.params.id);
    },
    async refresh() {
      await this.$store.dispatch('filters/getCollection');
      await this.$store.dispatch('boards/getById', this.$route.params.id);
    },
    open(task) {
      this.selected = task;
    },
    close() {
      this.selected = null;
    },
    action(index, id, action) {
      if (action === 'remove') {
        this.removeFilter(index, id);
        this.$trackEvent(`Filter removed from the board using menu`);
      }
    },
    update() {
      const stripped = this.filtersArr.filter(a => a.length);
      this.$store.dispatch('boards/update', { id: this.item.id, filters: stripped });
      this.filtersArr = [...stripped, []];
    },
    async addFilter(id) {
      const lock = this.$lock();
      try {
        await this.$store.dispatch('boards/update', { id: this.item.id, filters: Array.from(new Set([...this.item.filters, id])) });
      } catch (e) {
        this.$toast.error({
          title: 'Failed to add a filter',
          message: `Please, try again later or contact our development team.`
        });
      } finally {
        lock.release();
      }
    },
    async removeFilter(index, id) {
      const filters = [...this.filtersArr];
      filters[index] = filters[index].filter(item => item !== id);
      if (!filters[index].length) {
        filters.splice(index, 1);
      }
      await this.filtersListChanged(filters);
    },
    async filterUpdated(id) {
      await this.initialize();
      await this.$store.dispatch('boards/lists/getById', { id });
      await this.$store.dispatch('boards/lists/exec', { id, skip: 0 });
    },

    async filterCreated({ id }) {
      await this.addFilter(id);
      this.initialize();
    },
    async taskEdited() {
      this.$trackEvent(`Task saved using the board`);
      this.selected = null;
      for (const id of this.item.filters) {
        this.$store.dispatch('boards/lists/getById', { id }).then(() => this.$store.dispatch('boards/lists/exec', { id, skip: 0 }));
      }
    },

    async filtersListChanged(filters) {
      const lock = this.$lock();
      try {
        this.isReady = false;
        await this.$store.dispatch('boards/update', { id: this.item.id, filters });

        this.filtersArr = filters;
        this.isReady = true;
      } catch (e) {
        this.$toast.error({
          title: 'Failed to update filters list',
          message: `Please, try again later or contact our development team.`
        });
      } finally {
        lock.release();
        this.createModalVisible = false;
      }
    },
    onMassAction(action) {
      this.editAssigneesPopupOptions.mode = action;
    },
    taskToggled(taskId) {
      const index = this.selectedTasks.findIndex(id => id === taskId);
      if (index > -1) {
        this.selectedTasks.splice(index, 1);
      } else {
        this.selectedTasks.push(taskId);
      }
    },
    async assigneesMassAction(list) {
      const payload = {
        ids: this.selectedTasks
      };
      payload[this.editAssigneesPopupOptions.mode] = list;

      const confirmResult = await this.$confirm({
        title: `${this.editAssigneesPopupOptions.mode === 'add' ? 'Add assignees' : 'reassign'} `,
        message:
          this.editAssigneesPopupOptions.mode === 'add'
            ? `Are you sure you want to add ${list.join(', ')} to selected tasks?`
            : `Are you sure you want to reassign selected tasks to ${list.join(', ')}?`,
        confirm: this.editAssigneesPopupOptions.mode
      });

      this.editAssigneesPopupOptions.mode = null;
      if (confirmResult) {
        this.isReady = false;

        await this.$store.dispatch('tasks/assignTasks', payload);
        await new Promise(resolve => setTimeout(resolve, 1000));

        this.selectedTasks = [];
        this.isReady = true;
      }
    }
  }
};
</script>

<style lang="scss">
.board {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: max-content minmax(0, 1fr);
  > header {
    display: grid;
    grid-template-columns: max-content minmax(0, 1fr) max-content max-content max-content;
    grid-gap: 0.5rem;
    align-items: center;
    padding: 0.25rem 0;
    width: 100%;
    min-width: 0;

    h2 {
      text-overflow: ellipsis;
      overflow: hidden;
      background-color: transparent;
      white-space: nowrap;
    }
  }

  .mass-action-wrapper {
    display: flex;
    align-items: center;
  }
  .taskboard {
    display: grid;
    grid-auto-columns: 300px;
    grid-auto-flow: column;
    grid-gap: 1rem;
    width: 100%;
    height: 100%;
    overflow-x: auto;
    .sublist {
      display: grid;
      gap: 10px;
    }

    .action-list {
      // display: none;
      position: absolute;
      right: 0;
      background: rgb(255 0 0);
      z-index: 1;

      &.visible {
        display: block;
      }
    }
  }
}
</style>
